<template>
  <div>
    <b-card title="• 사용자 상세 정보">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="4">
            <b-form-group label="아이디 (이메일):" label-for="userId">
              <b-form-input
                id="userId"
                v-model="formData.userId"
                required
                style="height: 40px"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="이름:" label-for="username">
              <b-form-input
                id="username"
                v-model="formData.username"
                required
                style="height: 40px"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="상태:" label-for="status">
              <v-select
                v-model="formData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOption"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group label="비밀번호:" label-for="password">
              <b-form-input
                id="password"
                v-model="formData.password"
                type="password"
                style="height: 40px"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group label="권한:" label-for="roleId">
              <v-select
                v-model="formData.roleId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOption"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="권한 시작일:" label-for="roleStart">
              <b-form-datepicker
                id="roleStart-datepicker"
                v-model="formData.roleStart"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="권한 종료일:" label-for="roleEnd">
              <b-form-datepicker
                id="roleEnd-datepicker"
                v-model="formData.roleEnd"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mr-1"
              variant="success"
              @click="goLoginHist"
            >
              로그인 히스토리
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mr-1"
              variant="info"
              @click="goBuildHist"
            >
              건물정보 히스토리
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mr-1"
              variant="danger"
              @click="goBookmarkHist"
            >
              내 건물 등록 리스트
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-form-group>
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                @click="validationForm"
              >
                저장
              </b-button>
              <b-button variant="warning" class="mr-1" @click="onCancel">
                취소
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BCol,
    BCard,
    BRow,
    BFormGroup,
    BFormInput,
    BForm,
    BFormDatepicker,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      formData: {},
      statusOption: ['Y', 'N'],
      roleOption: ['ROLE_ADMIN', 'ROLE_USER','ROLE_GUEST'],
    };
  },
  async created() {
    this.formData = await this.$store.dispatch('admin/FETCH_ADMIN_INFO', {
      userId: this.$route.query.userId,
    });
  },
  methods: {
    async validationForm() {
      const res = await this.$store.dispatch(
        'admin/FETCH_ADMIN_UPDATE',
        this.formData,
      );
      alert(res.resultMsg);
      await this.$router.push({ path: '/admin-list' });
    },
    onCancel() {
      this.$router.push('/admin-list');
    },
    goLoginHist() {
      this.$router.push({
        path: '/admin-login-hst',
        query: { userId: this.$route.query.userId },
      });
    },
    goBuildHist() {
      this.$router.push({
        path: '/admin-build-hst',
        query: { userId: this.$route.query.userId },
      });
    },
    goBookmarkHist() {
      this.$router.push({
        path: '/admin-bookmark-hst',
        query: { userId: this.$route.query.userId },
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
